<template>
    <div id="seasfish" class="container">
        <div id="wrapper" :style="wrapperStyle">
            
        </div>
        
    </div>
</template>

<script>
    import animate, {stop} from 'animateplus'

    function testTmp (elements, curnL, curnT, speed) {
        const clientWidth = document.getElementById('seasfish').clientWidth
        const clientHeight = document.getElementById('seasfish').clientHeight
        const random = (min, max) =>
        Math.random() * (max - min) + min
        const newCurnT = random(10, clientHeight-200)
        const newCurnL = random(10, clientWidth-200)
        const durationRand = Math.ceil(Math.random() * 3000 + 3000 * speed)
        elements.addEventListener('click', ({target}) => stop(target))
        animate({
            elements,
            easing: 'linear',
            duration: durationRand,
            direction: 'normal',
            loop: false,
            transform: [`translateX(${curnL}px) translateY(${curnT}px)`, `translateX(${newCurnL}) translateY(${newCurnT})`]
        })
        .then(
            function () {
            testTmp(elements, newCurnL, newCurnT, speed)
            }
        )
    }
    
    export default {
        name: 'city-picker',
        data() {
            return {
            }
        },
        methods: {

            createFish (speed, cols, metrics, data) {
                const list = (length, callback) =>
                Array.from(new Array(length), (hole, index) => callback(index))

                console.log(list)
                const random = (min, max) =>
                    Math.random() * (max - min) + min

                const elements = data.forEach((record) => {
                    
                    const fishDiv = document.createElement('div')
                    const toolDiv = document.createElement('Tooltip')
                    const textDiv = document.createElement('div')
                    Object.assign(textDiv.style, {
                        position: 'absolute',
                        cursor: 'default',
                        height: '50px',
                        width: '160px',
                        backgroundImage: 'url("https://aybc.oss-cn-beijing.aliyuncs.com/sc/title.png")',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        color: 'rgb(38,141,177)',
                        fontFamily: '微软雅黑',
                        fontSize: '17px',
                        top: '-25px',
                        paddingLeft: '35px'
                    })
                    const fishImg = document.createElement('img')
                    Object.assign(fishDiv.style, {
                        position: 'absolute',
                        zIndex: '99999'
                    })
                    const size = random(100, 200)
                    fishImg.src = 'https://ossbao.oss-cn-qingdao.aliyuncs.com/web/yu.gif'
                    Object.assign(fishImg.style, {
                        width: `${size}px`,
                        left: `${random(0, 100)}%`,
                        top: `${random(0, 100)}%`,
                        position: 'absolute'
                    })
                    toolDiv.append(textDiv)
                    toolDiv.append(fishImg)
                    fishDiv.append(toolDiv)
                    document.getElementById('seasfish').append(fishDiv)
                    fishImg.addEventListener('onmouseover', function () { console.log('555555555555') })

                    const curnL = `${random(100, 1000)}`
                    const curnT = `${random(100, 1000)}`
                    testTmp(fishDiv, curnL, curnT, speed)
                    return fishDiv
                })
            },
            
        },
        computed: {
           
        },
       mounted() {
           const testData = [{'test': 1},{'test': 2}]
            this.createFish('0.5','','',testData);
       }
    }
</script>
