import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Fish from '@/components/Fish'
import Login from '@/components/Login'
import LoginSlider from '@/components/LoginSlider'
import Slider from '@/components/Slider'
import DragDemo from '@/components/dragDemo'

Vue.use(Router)

export default new Router({
  routes: [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
      path: '/fish',
      name: 'Fish',
      component: Fish
    },
    {
      path: '/slider',
      name: 'Slider',
      component: Slider
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/loginSlider',
      name: 'LoginSlider',
      component: LoginSlider
    },
    {
      path: '/dragDemo',
      name: 'DragDemo',
      component: DragDemo
    }
  ]
})

// 高版本vue-router需要加
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}