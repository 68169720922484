<template>
    <div>
        <DialogSlider ref="child" v-show="isMask" :propsItem="propsItem" @refresh-ok="refreshOk" @child-cancel="childcancel">
            
        </DialogSlider>
        
        <div class="register-box">
            <div class="title-blod">
                账&nbsp;号&nbsp;登&nbsp;录
            </div>
            <div class="account-line">
                <label class="uer-pic"></label>
                <input type="text" name="user" id="" value="" placeholder="请输入您的账号"  />
            </div>
            <div class="account-line">
                <label class="pass-pic"></label>
                <input type="password" name="password" id="" value="" placeholder="请输入您的密码"  />
            </div>
            <button class="en-btn" type="button" @click="clickBtn">确&nbsp;认&nbsp;登&nbsp;录</button>
            <button type="button" class="zhuce">
                <a href="###">用户注册</a>
            </button>
        </div>
    </div>
</template>

<script>
    import DialogSlider from './dialogSlider'
    export default {
        components: { DialogSlider },
        name: 'city-picker',
        data() {
            return {
                isMask:false,
                propsItem:{
                    title:'五福临门验证码',
                    blessContents: [
                        {num: "bless", isTarget: "0", image1: "1.png", image2: "11.png"},
                        {num: "bless", isTarget: "0", image1: "2.png", image2: "22.png"},
                        {num: "bless", isTarget: "1", image1: "3.png", image2: "33.png"},
                        {num: "bless", isTarget: "0", image1: "4.png", image2: "44.png"},
                        {num: "bless", isTarget: "0", image1: "5.png", image2: "55.png"},
                        ],
                    blessType: "Standard",
                    // blessType: "Alipay",
                    ok:'我是确认按钮',
                    cancel:'我是取消按钮',
                    isMask:true,
                    rgba:true
                }
                
            }
            
        },
        methods: {
            clickBtn(){
                this.propsItem.isMask = this.isMask = true
                // console.log(this.$refs.child)
            },
            childok(){
                alert('xxx')
            },
            childcancel(){

            },
            refreshOk(){
                // alert(1)
                if (this.propsItem.blessType === 'Standard'){
                    this.propsItem.blessType = 'Alipay'
                    this.propsItem.blessContents= [
                        {num: "bless", isTarget: "0", image1: "1.png", image2: "11.png"},
                        {num: "bless", isTarget: "1", image1: "2.png", image2: "22.png"},
                        {num: "bless", isTarget: "0", image1: "3.png", image2: "33.png"},
                        {num: "bless", isTarget: "0", image1: "4.png", image2: "44.png"},
                        {num: "bless", isTarget: "0", image1: "5.png", image2: "55.png"},
                        ]
                } else {
                    this.propsItem.blessType = 'Standard'
                    this.propsItem.blessContents= [
                        {num: "bless", isTarget: "0", image1: "1.png", image2: "11.png"},
                        {num: "bless", isTarget: "0", image1: "2.png", image2: "22.png"},
                        {num: "bless", isTarget: "0", image1: "3.png", image2: "33.png"},
                        {num: "bless", isTarget: "1", image1: "4.png", image2: "44.png"},
                        {num: "bless", isTarget: "0", image1: "5.png", image2: "55.png"},
                        ]
                }
            }
        },
        computed: {
            
        },
        mounted() {
           
        }
    }
</script>

<style>
html, body {height:100%; overflow:hidden;}
body{
	margin: 0;
	padding: 0;
	background: url(../assets/login.jpg) 50% no-repeat;
	position: relative;z-index: 1;
}
.register-box{
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	margin-left: 30px;
	margin-top: -226px;
	background: url(../assets/reg.png) center 310px no-repeat #FFF;
	border-radius: 10px;
	box-shadow: rgb(254 196 112 / 60%) 0px 3px 6px;
	width: 300px;
	height: 350px;
	padding: 24px;
	padding-bottom: 0;
}
.title-blod{
	font-weight: 550;
	color: #000;
	font-size: 1.5rem;
	line-height: 40px;
	letter-spacing: 0;
	text-align: center;
	margin-bottom: 20px;
}
.account-line{
	border: 1px #ccc solid;
	border-radius: 3px;
	overflow: hidden;
	margin-bottom: 25px;
	padding: 7px 15px 4px;
}
.account-line label{
	width: 24px;
	height: 28px;
	float: left;
	margin-right: 10px;
	
}
.uer-pic{
	background: url(../assets/user.png) 0 50% no-repeat;
}
.pass-pic{
	background: url(../assets/password.png) 0 50% no-repeat;
}
input{
	border: none;
	height: 28px;
	width: 228px;
	/* color: #666; */
	font-size: 14px;
	outline:none;
	line-height: 28px;
	font-size: 0.8rem;
}
.en-btn{
	width: 300px;
	height: 50px;
	background: #e7b801;
	border-radius: 3px;
	color: #fff;
	line-height: 50px;
	text-align: center;
	/* letter-spacing:20px; */
	border: none;
	color: #fff;
	font-size: 22px;
	outline: none;
}
.en-btn:hover {
    background: #e88802;
	
}
.en-btn:active {
    background: #e8a302;
	
}
.zhuce{
	background: none;
	width: 100%;
	height: 82px;
	border: none;
	margin-top: 25px;
	font-size: 12px;
	outline: none;
}
.zhuce a{
	color: #ecba00;
	font-size: 14px;
	text-align: center;
	line-height: 40px;
	display: block;
}
.parallelogram2 {
    width: 100px;
    height: 70px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    transform: skew(-20deg);
    background: #E5C3B2;
    border: #E88802 solid 1px;
    box-shadow:#ccc 3px 3px 6px ;
}
</style>