<template>
  <div class="wrapper">
        <img :src="url" class="logo">
        <h1 class="hello-text"></h1>
        <div style="marginTop:50px" >
          <Button type="warning" class="ibutton" @click="fish">海洋鱼</Button>
          <!-- <Button type="info" class="ibutton" @click="login">登录页</Button> -->
          <Button type="info" class="ibutton" @click="slider">滑块验证码</Button>
          <Button type="info" class="ibutton" @click="loginSlider">登录验证</Button>
          <Button type="info" class="ibutton" @click="dragDemo">拖拽</Button>
        </div>
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    data() {
      return {
        url: "../img/logo.png"
      }
    },
    methods: {
      fish() {
        this.$router.push({
          name: "Fish"
        })
      },
      login() {
        this.$router.push({
          name: "Login"
        })
      },
      loginSlider() {
        this.$router.push({
          name: "LoginSlider"
        })
      },
      dragDemo() {
        this.$router.push({
          name: "DragDemo"
        })
      },
      slider() {
        this.$router.push({
          name: "Slider"
        })
      }

    },
    mounted() {
      
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello {
    /* width: 100vw; */
    /* height: 100vh; */
    background-size: cover;
  }

  .hello-text {
    color: rgb(17, 16, 16);
    font-size: 34px;
    margin-top: 20px;
  }

  .wrapper {
    padding-top: 200px;
  }

  .logo {
    width: 322px;
    height: 85px;
    border-radius: 200px;
  }

  h1,
  h2 {
    font-weight: normal;
  }

  .ibutton {
    height: 80px;
    width: 150px;
    font-size: 24px;
    font-weight: 300;
    margin: 5px;
    box-shadow: 0 7px 0 #8bc220, 0 8px 3px rgba(0, 0, 0, 0.3);
    background-color: #A5DE37;
    border-color: #a5de370a;
    border-radius: 200px;
  }

</style>
