<template>
    <div>
        <div class="aa" v-drag>
                
        </div>
    </div>
</template>

<script>
    export default {
        name: 'city-picker',
        data() {
            return {
                isMask:false,
                
            }
            
        },
        // // 自定义指令
        // directives:{
        //     drag(el, bindings){
        //         el.onmousedown = function(e){
        //             var disx = e.pageX - el.offsetLeft;
        //             var disy = e.pageY - el.offsetTop;
        //             document.onmousemove = function (e){
        //                 el.style.left = e.pageX - disx + 'px';
        //                 el.style.top = e.pageY - disy + 'px';
        //             }
        //             document.onmouseup = function(){
        //                 document.onmousemove = document.onmouseup = null;
        //             }
        //         }
        //     }
        // },
        methods: {
           
        },
        computed: {
            
        },
        mounted() {
           
        }
    }
</script>

<style>
/* body {
  background: linear-gradient(45deg, #FC466B, #3F5EFB);
} */
.aa{
    position: absolute;
    width: 100px;
    height: 100px;
    background: #000;
}
</style>