<template>
    <!-- 福字转动div -->
    <div class="popContainer" v-show="propsItem.isMask" id="swingDiv">
        <!-- 源动div -->
        <div v-for="(item, index) in dragBlessContents"
            :key="index" class="dragDiv">
            <drag ref="source" class="dragBless" id="source"
                v-show="isStart"
                :transfer-data="true"
                    @dragstart="onDragStart"
                    @dragend="onDragEnd"
                    @drag="onDrag" v-if="!state.dragged"
                    @mouseover="testclick()"
                >
                    <figure  
                        :class="item.num"
                        :style="{'background': 'url(../img/' + propsItem.blessType + '/' + item.image1 +') no-repeat center center /100%','background-size': '100%'}"
                    ></figure>
            </drag >
        </div>
        <div class="main">
            <div :class="{dialog:propsItem.rgba}" v-if="propsItem.isMask">
                <div class="content" v-bind:style="propsItem.widthHeight">
                    <span class="close big" @click="refresh"><img style="width:17px;height:17px" src="https://ossbao.oss-cn-qingdao.aliyuncs.com/blog/2021/bless/refresh.ico"/>
                    <img style="width:16px;height:16px" src="https://ossbao.oss-cn-qingdao.aliyuncs.com/blog/2021/bless/close.ico" @click="close"></span>
                    
                    <div class="title"></div>
                    <hr class="hr1">
                    <div class="mainContent">
                        <!-- 目标div -->
                         <div class="dropDiv" v-for="(item, index) in propsItem.blessContents">
                            <drop class="dropBless" id="target" 
                                @dragover="onDragOver"
                                @dragleave="onDragLeave"
                                v-if="item.isTarget==='1'"
                            >
                                <figure 
                                    :key="index"
                                    class="bless"
                                    :class="{ 'over': state.over }"
                                    :style="{'background': 'url(../img/' + propsItem.blessType + '/' + item.image2 +') no-repeat center center /100%','background-size': '100%'}"
                                     ></figure>
                            </drop>
                            <figure v-if="item.isTarget==='0'"
                                :key="index"
                                :class="item.num"
                                :style="{'background': 'url(../img/' + propsItem.blessType + '/' + item.image1 +') no-repeat center center /100%','background-size': '100%'}"
                            ></figure>
                        </div>
                        <slot></slot>
                    </div>
                    <!-- <hr class="hr1"> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import animate from 'animateplus'
    import {stop} from 'animateplus'

    const random = (min, max) => Math.random() * (max - min) + min

    function swingDrag (elements, curnL, curnT, speed) {
       
        const clientWidth = document.getElementById('swingDiv').clientWidth
        const clientHeight = document.getElementById('swingDiv').clientHeight
        // 减去一个格的长度50
        const newCurnT = random(10, clientHeight-50)
        const newCurnL = random(10, clientWidth-50)
        
        const durationRand = Math.ceil(Math.random() * 3000 + 3000 * speed)
        animate({
            elements,
            easing: 'linear',
            duration: durationRand,
            direction: 'normal',
            loop: false,
            transform: [`translateX(${curnL}px) translateY(${curnT}px)`, `translateX(${newCurnL}) translateY(${newCurnT})`]
        })
        .then(
            function () {
                swingDrag(elements, newCurnL, newCurnT, speed)
            }
        )
        
    }

export default {
  name:'dialog1',
  props:{
      propsItem:{
          type:Object,
          default: function () {
              return {title:{
                  type:String,
                  default:'这是标题'
              },
              blessType:{
                  type:String,
                  default: "Alipay"
              },
              blessContents:{
                  type: Object,
                  default: []
              },
              ok:{
                  type:String,
                  default:'ok'
              },
              cancel:{
                  type:String,
                  default:'cancel'
              },
              isMask:{
                  type:Boolean,
                  default:true
              },
              rgba:{
                  type:Boolean,
                  default:true
              },
              widthHeight:{
                  width:'500px',
                  height:'500px'
              }}
          }
      }
  },
  data(){
    return {
        isStart: true,
        state: {
                over: false,
                dragging: false,
                dragged: false
            },
        trace: [],
        size: {
            width: 550,
            height: 350
        },
        block: {
            x: 150,
            y: 150
        },
        init:{}
    }
  },
  methods:{
      onDragOver() {
        this.state.over = true
      },
      onDragLeave() {
        this.state.over = false
      },
      onDragStart(data, event) {
        // this.isStart = false
        console.log("初始初始初始")
        console.log(this.state)
        this.init = {
            x: event.offsetX,
            y: event.offsetY,
        }
        this.trace = []
      },
      onDrag(data, event) {
        let offsetX = event.offsetX - this.init.x
        let offsetY = event.offsetY - this.init.y
        this.trace.push({
            x: offsetX,
            y: offsetY,
        })
        // 拖拽方式一
        // const drapDoc = document.getElementById("source")
        // drapDoc.onmousedown = function(e){
        //     var disx = e.pageX - drapDoc.offsetLeft;
        //     var disy = e.pageY - drapDoc.offsetTop;
        //     document.onmousemove = function (e){
        //         drapDoc.style.left = e.pageX - disx + 'px';
        //         drapDoc.style.top = e.pageY - disy + 'px';
        //     }
        //     document.onmouseup = function(){
        //         document.onmousemove = document.onmouseup = null;
        //     }
        // }
      },
      onDragEnd() {
        if (this.state.over) {
            this.state.dragging = false
            this.state.dragged = true
            console.log('验证成功')
            this.$toast.center('验证成功');
        }
        else {
            this.state.dragging = false
            this.state.dragged = false
            console.log('拖动失败')
        }
        this.state.over = false
      },
      test() {
        // alert(1)
      },
      testclick(){
        console.log("clickclick")
      },
      close(){
          this.propsItem.isMask = false
      },
      refresh(){
          this.$emit('refresh-ok')
      },
      okCallback(){
          this.$emit('child-ok')
          this.propsItem.isMask = false
      },
      cancelCallback(){
          this.$emit('child-cancel')
          this.propsItem.isMask = false
      },
      createDrag() {
        // const drapDoc = this.$refs.source.$el
        const drapDoc = document.getElementById("source")
        drapDoc.addEventListener('mouseover', ({target}) => {
            stop(drapDoc)
        })
        drapDoc.addEventListener('mouseout', ({target}) => {
            // 获取当前位置重新游动
            var translates = document.defaultView.getComputedStyle(document.getElementById("source"),null).transform;
            console.log(translates) //matrix(1, 0, 0, 1, 31, 21)
            
            //清除最后一个),便于后续取值
            let str = translates.replace(")","");
            console.log(translates) //matrix(1, 0, 0, 1, 31, 21
            
            //分割成数组
            let split = str.split(',');
            console.log(split); //(6) ["matrix(1", " 0", " 0", " 1", " 31", " 21"]
            
            //这就好取了，取最后俩个并转数字
            console.log(Number(split[split.length-2]),Number(split[split.length-1]));
            
            swingDrag(drapDoc, Number(split[split.length-2]), Number(split[split.length-1]), '0.3')
            
        })
        const curnL = `${random(100, 1000)}`
        const curnT = `${random(100, 1000)}`
        swingDrag(drapDoc, curnL, curnT, '0.5')
    },
  },
  computed: {
    // 根据数据获取源数据
    dragBlessContents: function () {
        return this.propsItem.blessContents.filter(function (item) {
            if (item.isTarget === "1"){
                return item
            }
        })
    },
    wrapperStyle() {
        return {
            position: 'relative',
            width: this.size.width + 'px',
            height: this.size.height + 'px',
            backgroundImage: 'url(' + this.image + ')',
            backgroundSize: 'cover'
        }
    },
    targetStyle() {
        return {
            left: this.block.x + 'px',
            top: this.block.y + 'px'
        }
    },
    sourceStyle() {
        return {
            backgroundImage: 'url(' + this.image + ')',
            backgroundSize: this.size.width + 'px ' + this.size.height + 'px',
            backgroundPosition: -this.block.x + 'px ' + -this.block.y + 'px'
        }
    },

  },
  mounted() {
    this.createDrag();
  },
//   // 自定义指令
//     directives:{
//         drag(el, bindings){
//             el.onmousedown = function(e){
//                 var disx = e.pageX - el.offsetLeft;
//                 var disy = e.pageY - el.offsetTop;
//                 document.onmousemove = function (e){
//                     el.style.left = e.pageX - disx + 'px';
//                     el.style.top = e.pageY - disy + 'px';
//                 }
//                 document.onmouseup = function(){
//                     document.onmousemove = document.onmouseup = null;
//                 }
//             }
//         }
//     }
  
}
</script>

<style >
    .hr1{
        position: relative;
        width: 100%;
        border:1px solid #cccccc;
    }
    .mainContent{
        position: relative;
        width: 100%;
        height: 60%;
        /* overflow-y: scroll; */
        /* margin-bottom: 5%; */
    }
    .dialog{
        /* background-color: rgba(0,0,0,0.8); */
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        box-shadow:5px 5px 13px #ccc;
    }
    .close.big {
        -webkit-transform: scale(2);
        -moz-transform: scale(2);
        -ms-transform: scale(2);
        -o-transform: scale(2);
        transform: scale(2);
    }
    .close{
        position: absolute;
        display: inline-block;
        overflow: hidden;
        right: 20px;
        top:20px;
        color:#000;
    }
    .content{
        height: 90%;
        /* border-radius: 4%; */
        padding: 1%;
        z-index: 999;
        text-align: left;
        
    }
    .cancel,.ok{
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .ok{
        right:100px;
    }
    .main {
        position: absolute;
        width: 60%;
        height: 40%;
        background: url(../assets/bg.jpg) no-repeat;
        background-size: 100%;
    }
    .title {
        position: relative;
        width: 40%;
        height: 20%;
        background: url(../assets/tishiyu.png) no-repeat;
        background-size: 100%;
    }
    div.popContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.78);
        z-index: 10;
    }
    .dragDiv{
        z-index: 66;
        position: absolute;
        float: left;
        width: 12%;
        height: 21.6%;
        left:0px;
        top:0px;
        
    }
    .dropDiv{
        position: relative;
        float: left;
        width: 20%;
        height: 100%;
    }
    /* 强制处理拖拽显示图片的位置 */
    /* .dragBless > div{
        top: 10px !important;
    } */
    .bless{
        /* position: fixed; */
        width: 100%;
        /* height: 100%; 下面两个保证高度等于宽度*/
        height:0;
        padding-bottom:100%;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;

        background-size: '100%';
        
        max-width: 100%;
        transform: rotate(45deg) scale(0.7);
    }
    .dragBless{
        width: 100%;
        /* height: 100%; */
    }
    .dropBless{
        width: 100%;
        /* height: 100%; */
    }
    .bless.over{
        -moz-box-shadow: 3px 5px 8px 8px #000; width: 110%; height: 110%;
        -webkit-box-shadow: 3px 5px 8px 8px #000; width: 110%; height: 110%;
        box-shadow: 3px 5px 8px 8px #000; width: 110%; height: 110%;
    }
</style>