<template>
    <div id="seasfish" class="container">
        <div id="wrapper" :style="wrapperStyle">
            
            <drop class="drop" id="target" 
                    :class="{ 'over': state.over }"
                    @dragover="onDragOver"
                    @dragleave="onDragLeave"
                    :style="targetStyle"
                >
                Dropzone
            </drop>
            <drag ref="source" class="drag" id="source"
                :transfer-data="true"
                @dragstart="onDragStart"
                @dragend="onDragEnd"
                @drag="onDrag" v-if="!state.dragged"
                :style="sourceStyle"
                 @mouseover="testclick()"
                >
                Drag Me
                <div class="drag" slot="image" id="float" :style="sourceStyle">
                </div>
            </drag >

        </div>
        <div>
                    <p v-if="state.dragged" id="trace">
                        拖动轨迹：{{ trace }}
                    </p>
        </div>
    </div>
</template>

<script>
    import animate, {stop} from 'animateplus'

    function testDragTmp (elements, curnL, curnT, speed) {
        const clientWidth = document.getElementById('seasfish').clientWidth
        const clientHeight = document.getElementById('seasfish').clientHeight
        // console.log('宽度宽度宽度宽度', clientWidth)
        const random = (min, max) =>
            Math.random() * (max - min) + min
        // alert(1)
        // 减去一个格的长度50
        const newCurnT = random(10, clientHeight-50)
        const newCurnL = random(10, clientWidth-50)
        
        const durationRand = Math.ceil(Math.random() * 3000 + 3000 * speed)
       
        animate({
            elements,
            easing: 'linear',
            duration: durationRand,
            // duration: `${random(10, 100)}*200`,
            direction: 'normal',
            loop: false,
            // "offset-distance": ['0%', '100%']
            // change: progress => {
            //     if (progress === 1) {
            //         this.testTmp()
            //     }
            // },
            // complete: function () { testTmp() },
            transform: [`translateX(${curnL}px) translateY(${curnT}px)`, `translateX(${newCurnL}) translateY(${newCurnT})`]
        })
        .then(
            function () {
            testDragTmp(elements, newCurnL, newCurnT, speed)
            }
        )
         elements.addEventListener('click', ({target}) => {
             stop(target)
             console.log(444444444444)
             console.log(target)
            //  testDragTmp(target, newCurnL, newCurnT, "0.1")
             })
    }
    export default {
        // components: { provinceCityCounty },
        name: 'city-picker',
        data() {
            return {
                state: {
                    over: false,
                    dragging: false,
                    dragged: false
                },
                trace: [],
                image: "https://ossbao.oss-cn-qingdao.aliyuncs.com/test/11111111111111.jpg",
                size: {
                    width: 550,
                    height: 350
                },
                block: {
                    x: 150,
                    y: 150
                },
                init:{}
            }
            
        },
        methods: {
            // myListener(myArg, transferData, nativeEvent) {
            // // myArg === 'foo'
            // // alert(1);
            // },
            // handleDrop(data, event) {
            //     alert(`You dropped with data: ${JSON.stringify(data)}`);
            // },
            onDragOver() {
                this.state.over = true
            },
            onDragLeave() {
                this.state.over = false
            },
            onDragStart(data, event) {
                console.log("初始初始初始")
                console.log(this.state)
                this.init = {
                    x: event.offsetX,
                    y: event.offsetY,
                }
                this.trace = []
            },
            onDrag(data, event) {
                let offsetX = event.offsetX - this.init.x
                let offsetY = event.offsetY - this.init.y
                this.trace.push({
                    x: offsetX,
                    y: offsetY,
                })
            },
            onDragEnd() {
                if (this.state.over) {
                    this.state.dragging = false
                    this.state.dragged = true
                    console.log('拖动成功')
                }
                else {
                    this.state.dragging = false
                    this.state.dragged = false
                    console.log('拖动失败')
                }
                this.state.over = false
            },

            createDrag() {
                const random = (min, max) =>
                    Math.random() * (max - min) + min
                const drapDoc = this.$refs.source.$el
                console.log(2222222222222222222)
                console.log(drapDoc)
                console.log(document.getElementById("source"))
                const curnL = `${random(100, 1000)}`
                const curnT = `${random(100, 1000)}`
                // testDragTmp(document.getElementById("source"), curnL, curnT, '0.5')
                testDragTmp(drapDoc, curnL, curnT, '0.5')
            },
            test() {
                alert(1)
            },
            testclick(){
                console.log("clickclick")
            }
            
        },
        computed: {
            // address() {
            //     const { selectProvince, selectCity, selectCounty } = this;
            //     return (selectProvince ? selectProvince : '') +
            //         (selectCity ? '，' + selectCity : '') +
            //         (selectCounty ? '，' + selectCounty : '');
            // }
            wrapperStyle() {
                return {
                    position: 'relative',
                    width: this.size.width + 'px',
                    height: this.size.height + 'px',
                    backgroundImage: 'url(' + this.image + ')',
                    backgroundSize: 'cover'
                }
            },
            targetStyle() {
                return {
                    left: this.block.x + 'px',
                    top: this.block.y + 'px'
                }
            },
            sourceStyle() {
                return {
                    backgroundImage: 'url(' + this.image + ')',
                    backgroundSize: this.size.width + 'px ' + this.size.height + 'px',
                    backgroundPosition: -this.block.x + 'px ' + -this.block.y + 'px'
                }
            },
            
        },
       mounted() {
           const testData = [{'test': 1},{'test': 2}]
            this.createDrag();
       }
    }
</script>

<style>
    /* .container {
        width: 800px;
        padding-top: 80px;
        margin: auto;
    } */
    /* .city-picker {
        margin-bottom: 20px;
    } */
    .drop {
        z-index: 66;
        position: absolute;
        box-shadow: 2px 3px 2px rgb(14, 13, 13);
        border: 1px solid #ccc;
        width: 50px;
        height: 50px;
    }
    .drag {
        z-index: 66;
        position: absolute;
        width: 50px;
        height: 50px;
    }
</style>