<template>
    <div>
        <Dialog  v-if="isMask" :propsItem="propsItem" @child-ok="childok" @child-cancel="childcancel">
            <template>
                <D1></D1>
            </template>
        </Dialog>
        <div class="container">
                
            <form >
                <p>登 录</p>
                <input type="email" placeholder="Email"><br>
                <input type="password" placeholder="密码"><br>
                <input type="button"  @click="clickBtn" value="提 交">
                    
            </form>

            <div class="drops">
                <div class="drop drop-1"></div>
                <div class="drop drop-2"></div>
                <div class="drop drop-3"></div>
                <div class="drop drop-4"></div>
                <!-- <div class="drop drop-5"></div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import animate, {stop} from 'animateplus'

    import Dialog from './dialog'
    export default {
        components: { Dialog },
        name: 'city-picker',
        data() {
            return {
                isMask:false,
                propsItem:{
                    title:'我是dialog标题',
                    contents:'我是dialog内容<input type="button" value="dialog" @click="clickBtn">',
                    ok:'我是确认按钮',
                    cancel:'我是取消按钮',
                    isMask:true,
                    rgba:true
                }
                
            }
            
        },
        methods: {
            clickBtn(){
                this.propsItem.isMask = this.isMask = true
            },
            childok(){
                alert('xxx')
            },
            childcancel(){

            }
        },
        computed: {
            
        },
        mounted() {
           
        }
    }
</script>

<style>
/* body {
  background: linear-gradient(45deg, #FC466B, #3F5EFB);
} */

.container {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

form {
  background: rgba(255, 255, 255, 0.3);
  padding: 3em;
  height: 300px;
  border-radius: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}
form p {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: #fff;
  opacity: 0.7;
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 60px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
form input {
  background: transparent;
  width: 200px;
  padding: 1em;
  margin-bottom: 2em;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5000px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
form input:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}
form input[type="email"]:focus, form input[type="password"]:focus {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}
form input[type="button"] {
  margin-top: 10px;
  width: 150px;
  font-size: 1rem;
}
form input[type="button"]:hover {
  cursor: pointer;
}
form input[type="button"]:active {
  background: rgba(255, 255, 255, 0.2);
}

::-webkit-input-placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

::-moz-placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

:-ms-input-placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

::-ms-input-placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

::placeholder {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.drop {
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.drop-1 {
  height: 80px;
  width: 80px;
  top: -20px;
  left: -40px;
  z-index: -1;
}
.drop-1:hover {
  -webkit-transform: translate(-2px, -3px);
          transform: translate(-2px, -3px);
}
.drop-2 {
  height: 80px;
  width: 80px;
  bottom: -30px;
  right: -10px;
}
.drop-2:hover {
  -webkit-transform: translate(3px, 4px);
          transform: translate(3px, 4px);
}
.drop-3 {
  height: 100px;
  width: 100px;
  bottom: 120px;
  right: -50px;
  z-index: -1;
}
.drop-3:hover {
  -webkit-transform: translate(4px, 0px);
          transform: translate(4px, 0px);
}
.drop-4 {
  height: 120px;
  width: 120px;
  top: -60px;
  right: -60px;
}
.drop-4:hover {
  -webkit-transform: translate(5px, -5px);
          transform: translate(5px, -5px);
}
.drop-5 {
  height: 60px;
  width: 60px;
  bottom: 170px;
  left: 90px;
  z-index: -1;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
</style>