import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Drag, Drop } from 'vue-drag-drop'
import vDrag from "v-drag"
import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';

Vue.config.productionTip = false
Vue.component('drag', Drag)
Vue.component('drop', Drop)
Vue.use(vDrag)
Vue.use(Toast);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
