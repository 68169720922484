<template>
    <!-- 处理this.$router.push方式跳转路由，否则不跳转 -->
    <div id="bg">
        <router-view/>
    </div>
</template>

<script>
  export default {
    name: 'bg'
  }

</script>
<style>
#bg {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  /* background: url(./assets/main_bg.jpg) no-repeat; */
  /* background-size: cover; */
}
</style>
